import './App.css';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
} from 'react-router-dom';

import Home from './Pages/Home';
import Ured from './Pages/Ured';
import Projekti from './Pages/Projekti';
import Kontakt from './Pages/Kontakt';
import Header from './Components/Header';
import Footer from './Components/Footer';


function App() {


    return (
        <BrowserRouter>
            <div className="App">
                <Header></Header>
                <hr></hr>
                <Routes>
                    <Route path='/' element={<Home />} exact></Route>
                    <Route path='/ured' element={<Ured />}></Route>
                    <Route path='/projekti' element={<Projekti />}></Route>
                    <Route path='/kontakt' element={<Kontakt />}></Route>
                </Routes>


                <Footer></Footer>
            </div>
        </BrowserRouter>
    );

}

export default App;

