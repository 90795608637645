import axios from "axios";
import { useState, useEffect } from 'react';
import { Suspense } from 'react';
import React from 'react';
import Gallery from '../Components/AliceGallery';

export default function Home() {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

   

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(
                    `https://dv.kopljar.com/Projekt`
                );
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    

    return (
        <div className="App">
            
            {loading /*&& <div>Učitavam ...</div>*/}
            {error && (
                <div>{`Problem - ${error}`}</div>
            )}

            {data &&
                data.map(({ projektID, url }) => (
                    
                    <div key={url}> 
                        <Suspense fallback={<div>Učitavam...</div>}>
                           {/* <img src={url} height="400px" width="600px" />*/}
                        </Suspense>
                        {/*{<h3>{url}</h3>}*/}
                    </div>
                ))}

            {/*    */}{/*<img src="https://mariokopljar.from.hr/IMG/POCETNE/1.jpg" height="400px" width="600px" />*/}
            {/*    */}{/*<img src="https://mariokopljar.from.hr/IMG/POCETNE/2.jpg" height="400px" width="600px" />*/}
            {/*    */}{/*<img src="https://mariokopljar.from.hr/IMG/POCETNE/3.webp" height="400px" width="600px" />*/}

            
            <Gallery></Gallery>



        </div>
    );



}


