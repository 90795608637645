import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();


var items = [
    <img src="https://mariokopljar.from.hr/IMG/POCETNE/1.jpg" alt="" className="gallery_img"  onDragStart={handleDragStart} role="presentation" />,
    <img src="https://mariokopljar.from.hr/IMG/POCETNE/2.jpg" alt="" className="gallery_img" onDragStart={handleDragStart} role="presentation" />,
    < img src="https://mariokopljar.from.hr/IMG/POCETNE/3.webp" alt="" className="gallery_img"   onDragStart={handleDragStart} role="presentation" />,
];

const Gallery = () => {

    //items = data_from_api.map(({ url }) => 
    //    <img key={url} src={url} alt="" className="gallery_img" onDragStart={handleDragStart} role="presentation" />    
    
    //);
    


    return (
        <AliceCarousel mouseTracking autoPlay={true} autoPlayInterval={2000} infinite={true} autoHeight={true} animationType="fadeout" items={items} />
    );
}

export default Gallery;