import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    return (
        <>
            <hr></hr>
            <div style={{ paddingLeft: 50, paddingRight: 50, paddingTop:10,  paddingBottom:40 }}>

                <div style={{ display: "inline", float: "left" }}>
                    &copy; 2023 &nbsp;- &nbsp;
                    <Link to="/" style={{textDecoration:'none'} }>DIV <span style={{ color: "#909090" }}>habitat</span></Link>
                    
                </div>
                <div style={{ display: "inline", float: "right" }}>
                    <SocialIcon url="https://www.facebook.com/DIV-Habitat-1539772259662523" style={{ height: 25, width: 25 }} bgColor='black' />
                </div>
            </div>
        </>
    )
};

export default Footer;