import axios from "axios";
import { useState, useEffect } from 'react';
import { Suspense } from 'react';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();

var items = [];
//var items = [
//    <img src="https://mariokopljar.from.hr/IMG/POCETNE/1.jpg" alt="" className="gallery_img"  onDragStart={handleDragStart} role="presentation" />,
//    <img src="https://mariokopljar.from.hr/IMG/POCETNE/2.jpg" alt="" className="gallery_img" onDragStart={handleDragStart} role="presentation" />,
//    < img src="https://mariokopljar.from.hr/IMG/POCETNE/3.webp" alt="" className="gallery_img"   onDragStart={handleDragStart} role="presentation" />,
//];

export default function Galerija({projektID }) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const xxx = `https://dv.kopljar.com/Projekt?id=${ projektID }`;

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(xxx
                    /*`https://dv.kopljar.com/Projekt?id={projektID}`*/
                );
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    if (data) {
        items = data.map(({ url }) =>
            <img key={url} src={url} alt="" className="gallery_img" onDragStart={handleDragStart} role="presentation" />

        );
    }
    


    return (

        <div className="App">

            {loading /*&& <div>Učitavam ...</div>*/}
            {error && (
                <div>{`Problem - ${error}`}</div>
            )}

            {/*{data &&*/}
            {/*    data.map(({ projektID, url }) => (*/}

            {/*        <div key={url}>*/}
            {/*            <Suspense fallback={<div>Učitavam...</div>}>*/}
            {/*                */}{/* <img src={url} height="400px" width="600px" />*/}
            {/*            </Suspense>*/}
            {/*            */}{/*{<h3>{url}</h3>}*/}
            {/*        </div>*/}
            {/*    ))}*/}

            <AliceCarousel mouseTracking autoPlay={true} autoPlayInterval={2000} infinite={true} autoHeight={true} animationType="fadeout" items={items} />

        </div>
    );
}

