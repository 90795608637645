import classes from './Kontakt.module.css';



const Kontakt = () => {
    return (
        <>
            <h1>
                Kontakt
            </h1>

            <div>

                <div className={classes.mario}  >
                    Kontakt obrazac
                </div>
                <div  >
                    <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.230163299559!2d15.896082976250174!3d45.806648671081625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d11531c9ca35%3A0x75bffe054086dd38!2sUl.%20Ivane%20Brli%C4%87%20Ma%C5%BEurani%C4%87%2044%2C%2010000%2C%20Zagreb!5e0!3m2!1shr!2shr!4v1681379148479!5m2!1shr!2shr" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>


                <img alt="" src={require('../Images/1.jpg')} />

            </div>
        
        
        
        
        
        </>
        
    )
};

export default Kontakt;