import React from 'react';
import Galerija from '../Components/Galerija';

export default function Projekti() {

    
    
    

    return (
        <div>
            <div>
                <h1>Projekti</h1>
            </div>
            <div>
                <Galerija projektID={1}></Galerija>
            </div>            
        

        </div>
    );



}


