import React, { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { hover } from "../../node_modules/@testing-library/user-event/dist/hover";
import classes from './Header.module.css';

function Header() {

    const [openDrawer, toggleDrawer] = useState(false);
    const drawerRef = useRef(null);

    const location = useLocation();
    useEffect(() => {
        toggleDrawer(false);
        console.log("url changed")
    }, [location]);


    useEffect(() => {
        /* Close the drawer when the user clicks outside of it */
        const closeDrawer = event => {
            if (drawerRef.current && drawerRef.current.contains(event.target)) {
                return;
            }

            toggleDrawer(false);
        };

        

        document.addEventListener("mousedown", closeDrawer);
        return () => document.removeEventListener("mousedown", closeDrawer);
    }, []);

    


    return (
        <Styles.Wrapper>
            <CSSReset />

            <Navbar.Wrapper>
                <Navbar.Logo>
                    <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                        <Link to="/">
                            <img src={require('./logo.svg').default} alt='mySvgImage' height='30px' style={{ marginRight: 20 }} />
                        </Link>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            DIV <span style={{ color: "#909090" }}>habitat</span>
                        </Link>
                    </div>
                </Navbar.Logo>

                <HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
                    <HamburgerButton.Lines />
                </HamburgerButton.Wrapper>

                <Navbar.Items ref={drawerRef} openDrawer={openDrawer}>
                    
                   {/* <div style={{ paddingTop: openDrawer ? 50 : 0 }}></div>*/}

                    {openDrawer &&
                        <button className={classes.close_btn} onClick={() => toggleDrawer(false) }>X</button>
                    }



                    <Navbar.Item>
                        <NavLink to="/">Početna</NavLink>
                    </Navbar.Item>
                    <Navbar.Item>
                        <NavLink to="/ured">O nama</NavLink>
                    </Navbar.Item>
                    <Navbar.Item>
                        <NavLink to="/projekti">Projekti</NavLink>
                    </Navbar.Item>
                    <Navbar.Item>
                        <NavLink to="/kontakt">Kontakt</NavLink>
                    </Navbar.Item>
                </Navbar.Items>
            </Navbar.Wrapper>
            
        </Styles.Wrapper>
        
    );

}

const Styles = {
    Wrapper: styled.main`
    display: flex;
    //background-color: #eeeeee;
    //height: 100vh;
  `
};

const Navbar = {
    Wrapper: styled.nav`
    flex: 1;

    align-self: flex-start;

    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;

    // 40em == 640px
    @media only screen and (max-width: 20em) {
      position: fixed;
      width: 100vw;
      bottom: 0;
    }
  `,
    Logo: styled.h1`
    /*border: 1px solid gray;*/
    padding: 0.5rem 1rem;
  `,
    Items: styled.ul`
    display: flex;
    list-style: none;
    
    @media only screen and (max-width: 40em) {
      position: fixed;
      right: 0;
      top: 0;
        
        font-size:2rem;  /* veći font za nav items na mobilnom */

      height: 100%;
      width: 75%;
      text-align:left;

      flex-direction: column;

      background-color: #f2f2f2;     /* canvas background */
      z-index:9999;
      padding: 5rem 2rem;

      transition: 0.6s ease-out;

      transform: ${({ openDrawer }) =>
            openDrawer ? `translateX(0)` : `translateX(100%)`};
    }
  `,
    Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
    @media only screen and (max-width: 40em) {
      padding: 1rem 0;
    }
  `
    
};

const HamburgerButton = {
    Wrapper: styled.button`
    height: 3rem;
    width: 3rem;
    position: relative;
    font-size: 12px;

    display: none;

    @media only screen and (max-width: 40em) {
      display: block;
    }

    /* Remove default button styles */
    border: none;
    background: transparent;
    outline: none;

    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;
    }
  `,
    Lines: styled.div`
    top: 50%;
    margin-top: -0.125em;

    &,
    &:after,
    &:before {
      /* Create lines */
      height: 3px;
      pointer-events: none;
      display: block;
      content: "";
      width: 100%;
      background-color: black;
      position: absolute;
    }

    &:after {
      /* Move bottom line below center line */
      top: -0.8rem;
    }

    &:before {
      /* Move top line on top of center line */
      top: 0.8rem;
    }
  `
};

const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
    margin: 0; 
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; /*1rem = 10px*/
    box-sizing: border-box;      
  }  

  body {
    font-size: 1.4rem;
    font-family: sans-serif;
  }
`;

export default Header;

